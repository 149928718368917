// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../design/mockup/assets/css/reset.css'
import '../design/mockup/assets/css/layout.css'
import '../stylesheets/application.scss'

require('@rails/ujs').start()
require('@rails/activestorage').start()
require('jquery')
require('jquery-ui/ui/widgets/datepicker')
require('jquery-ui/ui/widgets/autocomplete')
require('decimal.js')
require('../design/mockup/assets/js/main.js')

require.context('../design/mockup/assets/img/', true)
require.context('../../assets/images/', true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
