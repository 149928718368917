jQuery(function ($) {
  $('tbody tr[data-href]').addClass('clickable').click(function () {
    window.location = $(this).attr('data-href');
  }).find('a').hover(function () {
    $(this).parents('tr').unbind('click');
  }, function () {
    $(this).parents('tr').click(function () {
      window.location = $(this).attr('data-href');
    });
  });
});

//table hover
jQuery(function ($) {
  $('tr[data-href]').addClass('clickable')
      .click(function (e) {
        if (!$(e.target).is('a')) {
          window.location = $(e.target).closest('tr').data('href');
        };
      });
});


var windowWidth = $(window).width();
var windowSm = 1080;
if (windowWidth <= windowSm) {
  //横幅1080px以下

  //menu open
  $(function () {
    $("#menuButton").click(function () {
      $(this).toggleClass("active");

      /*-- メニューの開閉 --*/
      if ($(this).hasClass("active")) {
        $(".sidebar").animate({
          width: "60px",
        }, 200);
        $(".sidebar nav li a span").css("display", "none");
        $(".siten-box").css("display", "none");
        $(".main").css("margin-left", "60px");
      } else {
        $(".sidebar").animate({
          width: "230px",
        }, 200);
        $(".sidebar nav li a span").css("display", "block");
        $(".siten-box").css("display", "block");

      }

      return false;
    });
  });


  ////////////////
} else {
  //横幅1080px超のとき

  //menu open
  $(function () {
    $("#menuButton").click(function () {
      $(this).toggleClass("active");

      /*-- メニューの開閉 --*/
      if ($(this).hasClass("active")) {
        $(".sidebar").animate({
          width: "60px",
        }, 200);
        $(".sidebar nav li a span").css("display", "none");
        $(".siten-box").css("display", "none");
        $(".main").css("margin-left", "60px");
      } else {
        $(".sidebar").animate({
          width: "230px",
        }, 200);
        $(".sidebar nav li a span").css("display", "block");
        $(".siten-box").css("display", "block");
        $(".main").css("margin-left", "230px");

      }

      return false;
    });
  });


  ////////////////
}


// mainArea hight
$(document).ready(function () {
  adjust();

  $(window).resize(function () {
    adjust();
  })

  function adjust() {
    var h = $(window).height();
    $('#home').css("height", h);
  }
});


//ページトップ
$(document).ready(function () {
  $("#topBtn").hide();
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 100) {
      $("#topBtn").fadeIn("fast");
    } else {
      $("#topBtn").fadeOut("fast");
    }
  });
  $('#topBtn').click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 400);
    return false;
  });
});


//sidebar fixed
$(function () {

  if ($('.sidebar').length > 0) {
    var offset = $('.sidebar').offset();

    $(window).scroll(function () {
      if ($(window).scrollTop() > offset.top) {
        $('.sidebar').addClass('fixed');
      } else {
        $('.sidebar').removeClass('fixed');
      }
    });
  }

});


//step scroll
$(function () {
  // ナビゲーションのリンクを指定
  var navLink = $('.step-nav ol li a');
  var headerHight = 20;
  var contentsArr = new Array();

  // 各コンテンツのページ上部からの開始位置と終了位置を配列に格納しておく
  function contentPostionCheck() {
    contentsArr = new Array();
    navLink = $('.step-nav.current ol li a');
    for (var i = 0; i < navLink.length; i++) {
      // コンテンツのIDを取得
      var targetContents = navLink.eq(i).attr('href');
      // ページ内リンクでないナビゲーションが含まれている場合は除外する
      if (targetContents.charAt(0) == '#') {
        // ページ上部からコンテンツの開始位置までの距離を取得
        var targetContentsTop = $(targetContents).offset().top;
        // ページ上部からコンテンツの終了位置までの距離を取得
        var targetContentsBottom = targetContentsTop + $(targetContents).outerHeight(true) - 1;
        // 配列に格納
        contentsArr[i] = [targetContentsTop, targetContentsBottom]

      }
    };
    //console.info(contentsArr)
  }
  contentPostionCheck()


  // 現在地をチェックする
  function currentCheck() {
    // 現在のスクロール位置を取得
    var windowScrolltop = $(window).scrollTop();

    for (var i = 0; i < contentsArr.length; i++) {
      // 現在のスクロール位置が、配列に格納した開始位置と終了位置の間にあるものを調べる
      if (contentsArr[i][0] <= windowScrolltop + headerHight + 10 && contentsArr[i][1] >= windowScrolltop + headerHight + 10) {
        // 開始位置と終了位置の間にある場合、ナビゲーションにclass="current"をつける
        navLink.removeClass('current');
        navLink.eq(i).addClass('current');
        i == contentsArr.length;
      }
    };
    //ページ末端で最後を選択
    if (windowScrolltop + $(window).height() == $(document).height()) {
      navLink.removeClass('current');
      navLink.eq(-1).addClass('current');
    }
  }

  if ($(".btn-footerArea .step-nav, .btn-footerAreaB .step-nav").length > 0) {

    // ページ読み込み時とスクロール時に、現在地をチェックする
    $(window).on('load scroll resize', function () {
      contentPostionCheck()
      currentCheck();

      //一定時間でフェードアウト
      $(".step-nav").clearQueue().stop().css({opacity: 1.0}).delay(2000).animate({opacity: 0.0}, 500);

    });

    $(".step-nav .step-box-close img").click(
        function(){
          $(".step-nav").hide();
        }
    );


    $(".step-nav").hover(
        function() {
          $(".step-nav").addClass("hover");
        },
        function() {
          $(".step-nav").removeClass("hover");
        }
    );


    $('html,body').mousemove(function(){
      if($(".step-nav").hasClass("hover")){
        $(".step-nav").clearQueue().stop().css({opacity: 1.0 });
      } else {
        $(".step-nav").clearQueue().stop().css({opacity: 1.0}).delay(2000).animate({opacity: 0.0}, 500);
      }
    })

    // ナビゲーションをクリックした時のスムーズスクロール
    $('.step-nav ol li a').click(function () {
      $('html,body').animate({
        scrollTop: $($(this).attr('href')).offset().top - headerHight
      }, 300);
      return false;
    })

  }
});

//新規申込 法人・個人のUI切替
$(function () {

  $('#entry_type input[type="radio"]').change(function () {
    var result = $(this).data('entryType');
    changeEntryType(result)
    console.log(result);
  })

  function changeEntryType(result) {

    if (result == "personal") {
      $("#kojin").show();
      $("#houjin").hide();
      $("#kojin .step-nav").addClass("current");
      $("#houjin .step-nav").removeClass("current");

      $(".application").hide()
      $('#kojin ol li a').each(function () {
        $($(this).attr("href")).show();
      })
    }
    if (result == "corporate") {
      $("#kojin").hide();
      $("#houjin").show();
      $("#kojin .step-nav").removeClass("current");
      $("#houjin .step-nav").addClass("current");

      $(".application").hide()
      $('#houjin ol li a').each(function () {
        $($(this).attr("href")).show();
      })
    }
  }

  //デフォルト処理
  changeEntryType($('#entry_type input[type="radio"]:checked').data('entryType'))
});


//確認ボタン
$(function () {
  $(".btn-kakunin-box").click(function () {
    $(this).html("<div class='label-kakunin'>確認しました</div>");
  });
});

$(function () {
  $(".btn-kakunin-ttl").click(function () {
    $(this).html("<div class='label-kakunin'>確認しました</div>");
  });
});


//toast close
$(function () {
  $('.toast .close').click(function () {
    $('.toast').css('display', 'none')
  })
});


// サジェストUI（物件名）
// check: https://jqueryui.com/autocomplete/
$(function () {

  var dataList = [
    'サンライト渋谷マンション',
    'パークナード渋谷',
    'ピアーズ渋谷WEST',
    'シティタワー渋谷',
    'ピアーズ渋谷WEST',
    'シティタワー渋谷',
    'ピアーズ渋谷WEST',
    'シティタワー渋谷',
    'ピアーズ渋谷WEST',
    'シティタワー渋谷',
  ];

  if ($('#autocomplete_search').length > 0) {
    $('#autocomplete_search').autocomplete({
      source: dataList,
      autoFocus: true,
      delay: 300,
      minLength: 1,
      select: function (event, ui) {
        return ui.item.value;
      }
    })
        .autocomplete("instance")._renderItem = function (ul, item) {
      var word = $('#autocomplete_search').val()
      var label = item.label.replace(word, "<b>" + word + "</b>")
      return $("<li>")
          .append("<div class='history_label'>" + label + "</div><div class='history_btn'><a href='javascript:void(0)' onclick='removeHistory(\"" + item.label + "\")'>x</a></div>")
          .appendTo(ul);
    };
  }

});

function removeHistory(v) {
  window.confirm("物件名履歴の「" + v + "」を削除しますか？")
}


// サジェストUI（金融機関）
// check: https://jqueryui.com/autocomplete/
$(function () {

  //ゆうちょ銀行以外（金融機関情報）
  var bankList = [
    "みずほ銀行(0001)",
    "三菱ＵＦＪ銀行(0005)",
    "三井住友銀行(0009)",
    "りそな銀行(0010)",
    "埼玉りそな銀行(0017)",
    "ジャパンネット銀行(0033)",
    "セブン銀行(0034)",
    "ソニー銀行(0035)",
    "楽天銀行(0036)",
    "住信ＳＢＩネット銀行(0038)",
    "auじぶん銀行(0039)",
    "イオン銀行(0040)",
    "大和ネクスト銀行(0041)",
    "ローソン銀行(0042)",
  ];

  if ($('.autocomplete_bank').length > 0) {
    $('.autocomplete_bank').autocomplete({
      source: bankList,
      autoFocus: true,
      delay: 300,
      minLength: 1,
      select: function (event, ui) {
        var bank_code = ui.item.value.split("(")[1].replace(")", "");
        var bank_name = ui.item.value.split("(")[0];
        $(this).parents(".content_area").find('.bank_code').val(bank_code);
        $(this).parents(".content_area").find('.bank_name').val(bank_name);
        $('.autocomplete_bank').trigger("change");
        return false;
      }
    })

    $('.autocomplete_bank').change(function () {
      //金融機関情報があれば、支店コードを活性化
      var bank_name = $(this).parents(".content_area").find('.bank_name').val()
      var bank_code = $(this).parents(".content_area").find('.bank_code').val()
      $(this).parents(".content_area").find(".autocomplete_branch").prop('disabled', bank_name + bank_code == "");
    })
  }
  $('.autocomplete_bank').trigger("change");

  //ゆうちょ銀行以外（支店情報）
  var branchList = [
    "東京営業部(001)",
    "丸の内中央支店(004)",
    "丸之内支店(005)",
    "神田駅前支店(009)",
    "町村会館出張所(013)",
    "築地支店(015)",
    "確定拠出年金支店(019)",
    "押上支店(020)",
    "麹町支店(021)",
    "浅草橋支店(022)",
    "京橋支店(024)",
    "八重洲口支店(026)",
    "兜町支店(027)",
    "銀座通支店(028)",
    "銀座支店(035)",
    "四谷支店(036)",
    "日本橋支店(038)",
    "横山町支店(040)",
    "白金出張所(044)",
    "虎ノ門支店(046)",
    "本所支店(050)",
    "新橋中央支店(051)",
    "六本木支店(053)",
    "芝支店(054)",
    "広尾支店(057)",
    "飯田橋支店(061)",
    "高田馬場支店(064)",
    "新宿中央支店(066)",
    "早稲田支店(068)",
    "新宿南口支店(069)",
    "本郷支店(075)",
    "東京法人営業部(078)",
    "大阪法人支店(080)",
    "神戸法人支店(081)",
    "名古屋法人支店(082)",
    "福岡法人支店(083)",
    "仙台法人支店(084)",
    "富山法人支店(085)",
    "広島法人支店(086)",
    "札幌法人支店(087)",
    "高松法人支店(088)",
    "稲荷町支店(090)",
    "横浜法人支店(091)",
    "京都法人支店(092)",
    "新宿法人支店(093)",
    "浜松法人支店(096)",
    "本店(100)",
  ];

  if ($('.autocomplete_branch').length > 0) {
    $('.autocomplete_branch').autocomplete({
      source: branchList,
      autoFocus: true,
      delay: 300,
      minLength: 1,
      select: function (event, ui) {
        var branch_code = ui.item.value.split("(")[1].replace(")", "");
        var branch_name = ui.item.value.split("(")[0];
        $(this).parents(".content_area").find('.branch_code').val(branch_code)
        $(this).parents(".content_area").find('.branch_name').val(branch_name)
        return false;
      }
    })
  }


  //ゆうちょ銀行（支店情報）
  var jppostList = [
    "〇〇八店(008)",
    "〇一八店(018)",
    "〇二八店(028)",
    "〇三八店(038)",
    "〇四八店(048)",
    "〇五八店(058)",
    "〇六八店(068)",
    "〇七八店(078)",
    "〇八八店(088)",
    "〇九八店(098)",
    "一〇八店(108)",
    "一一八店(118)",
    "一二八店(128)",
    "二〇八店(208)",
    "二一八店(218)",
    "二二八店(228)",
    "二三八店(238)",
    "二四八店(248)",
    "三一八店(318)",
    "三二八店(328)",
    "三三八店(338)",
    "四〇八店(408)",
    "四一八店(418)",
    "四二八店(428)",
    "四三八店(438)",
    "四四八店(448)",
    "四五八店(458)",
    "四六八店(468)",
    "四七八店(478)",
    "五一八店(518)",
    "五二八店(528)",
    "五三八店(538)",
    "五四八店(548)",
    "五五八店(558)",
    "六一八店(618)",
    "六二八店(628)",
    "六三八店(638)",
    "六四八店(648)",
    "七〇八店(708)",
    "七一八店(718)",
    "七二八店(728)",
    "七三八店(738)",
    "七四八店(748)",
    "七六八店(768)",
    "七七八店(778)",
    "七八八店(788)",
    "七九八店(798)",
    "八一八店(818)",
    "八二八店(828)",
    "八三八店(838)",
    "八四八店(848)",
    "八五八店(858)",
    "八六八店(868)",
    "九〇八店(908)",
    "九一八店(918)",
    "九二八店(928)",
    "九三八店(938)",
    "九四八店(948)",
    "九五八店(958)",
    "九六八店(968)",
    "九七八店(978)",
    "九八八店(988)",
    "九九八店(998)",
  ];

  if ($('.autocomplete_jppost').length > 0) {
    $('.autocomplete_jppost').autocomplete({
      source: jppostList,
      autoFocus: true,
      delay: 300,
      minLength: 1,
      select: function (event, ui) {
        var branch_code = ui.item.value.split("(")[1].replace(")", "");
        var branch_name = ui.item.value.split("(")[0];
        $(this).parents(".content_area").find('.branch_code').val(branch_code)
        $(this).parents(".content_area").find('.branch_name').val(branch_name)
        return false;
      }
    })
  }


});


//操作メニュー
$(function () {
  $('.sousa-nav-box').css({
    top: -10000,
    zIndex: 10000,
    position: "absolute"
  });

  $('.btn-sousa').click(function () {
    var _top = $(this).offset().top + $(this).height() + 10;
    var _left = $(this).offset().left - $('.sousa-nav-box').width();
    $('.sousa-nav-box').offset({
      top: _top,
      left: _left
    });
    $('.sousa-wrap-box').hide().fadeIn();
    return false;
  })

  if ($('.sousa-nav-box').length > 0) {

    //範囲外をクリックした際は非表示
    $('body').click(function () {
      if (!$('.sousa-wrap-box').is(':hidden')) {
        //$('.sousa-wrap-box').fadeOut();
        $('.sousa-nav-box').css({
          top: -10000
        });
      }
    })

    //スクロールした際は非表示
    $(window).resize(function () {
      $('.sousa-nav-box').css({
        top: -10000
      });
    });

  }


});


//新規代理店ご利用申し込み 同意チェック
$(function () {

  $(".kiyaku-rikai").css({
    opacity: 0.5
  })
  $("a.btn-kiyaku-download").click(function () {
    $("#check-doui").prop("disabled", false);
    $(".kiyaku-rikai").css({
      opacity: 1.0
    })
  });

  $('#check-doui').click(function () {
    if ($("#check-doui").prop('checked')) {
      $('#btn-doui').prop('disabled', false);
    } else {
      $('#btn-doui').prop('disabled', true);
    }
  })
});


$(function () {
  $('#check-doui2').click(function () {
    if ($("#check-doui2").prop('checked')) {
      $('#btn-moushikomi').prop('disabled', false);
    } else {
      $('#btn-moushikomi').prop('disabled', true);
    }
  })
});

$(function () {
  $('#check-doui3').click(function () {
    if ($("#check-doui3").prop('checked')) {
      $('#btn-kakunin').prop('disabled', false);
      $('#btn-next').prop('disabled', false);
    } else {
      $('#btn-kakunin').prop('disabled', true);
      $('#btn-next').prop('disabled', false);
    }
  })
});

$(function () {
  $('#check-doui4').click(function () {
    if ($("#check-doui4").prop('checked')) {
      $('#upload-smartphone').css('display', 'block');
    } else {
      $('#upload-smartphone').css('display', 'none');
    }
  })
});

$(function () {
  $('#check-doui5').click(function () {
    if ($("#check-doui5").prop('checked')) {
      $('#btn-moushikomi').prop('disabled', false);
      $('#btn-select').prop('disabled', false);
      $('#btn-next').prop('disabled', false);
    } else {
      $('#btn-moushikomi').prop('disabled', true);
      $('#btn-select').prop('disabled', true);
      $('#btn-next').prop('disabled', true);
    }
  })
});


$(function () {
  $("#btn-keiyaku-kanryou").prop("disabled", true);
  $("input[type='checkbox']").on('change', function () {
    if ($(".keiyaku-check-s:checked").length > 2) {
      $("#btn-keiyaku-kanryou").prop("disabled", false);
    } else {
      $("#btn-keiyaku-kanryou").prop("disabled", true);
    }
  });
});


$(function () {

  // テーブルのクリックイベントを解除
  $('.check-tainou').off("click")
  $('.check-tainou').parents("tr").off("click")

  // テーブル チェックボックスがあるときのクリックイベントを解除
  $('td .checkbox-parts').hover(
      function () {
        $('td .checkbox-parts').parents("tr").off("click")
      },
      function () {
        $('tr[data-href]').addClass('clickable')
            .click(function (e) {
              if (!$(e.target).is('a')) {
                window.location = $(e.target).closest('tr').data('href');
              }
            })
      }
  );


  // 滞納状況をデフォルト非表示
  $(".div-jyoukyou").hide();
  $(".div-jyoukyou-notice").hide();

  // 対象月チェック済の滞納情報を表示
  $(".check-tainou").each(function () {
    if ($(this).prop('checked')) {
      $(this).parents("tr").find(".div-jyoukyou").show()
    }
  })


  // 対象月チェックの変更イベント
  $('.check-tainou').change(function (e) {

    var chk_first = false;
    var chk_second = false;
    var chk_disabled = false;


    //$('.check-tainou').each(function(i){
    $(this).parents("table").find('.check-tainou').each(function (i) {

      if (!chk_first && $(this).prop('checked')) {
        chk_first = true;
      }

      if (chk_first && !$(this).prop('checked')) {
        chk_second = true;
      }

      if ($(this).prop('checked') && $(this).prop('disabled')) {

        // チェック済かつ編集不可（依頼中／処理中／解決済）の場合は、滞納状況を表示
        $(this).parents("tr").find(".div-jyoukyou").show()
        $(this).parents("tr").find(".div-jyoukyou-notice").hide()
        $(this).parents("tr").find(".jyoukyou").prop("disabled", true)

        //以降は変更不可フラグ
        chk_disabled = true;

      } else if (chk_disabled) {

        //依頼中/処理中/解決済になっている最新の月よりも過去の月にはチェックを付けられない
        $(this).prop("disabled", true)

      } else if ($(this).prop('checked')) {

        //
        if (chk_second) {
          $(this).parents("tr").find(".div-jyoukyou").hide()
          $(this).parents("tr").find(".div-jyoukyou-notice").show()
        } else {
          $(this).parents("tr").find(".div-jyoukyou").show()
          $(this).parents("tr").find(".div-jyoukyou-notice").hide()
        }

      } else {
        $(this).parents("tr").find(".div-jyoukyou").hide()
        $(this).parents("tr").find(".div-jyoukyou-notice").hide()
      }


    })


    // if ($(this).prop('checked')) {
    //   $(this).parents("tr").find(".div-jyoukyou").show()
    // } else {
    //   $(this).parents("tr").find(".div-jyoukyou").hide()
    // }
  })

  $('.check-tainou').trigger("change");


});


function myCheck() {
  var flag = false; // 選択されているか否かを判定する変数

  for (var i = 0; i < document.checkboxKeiyaku.length; i++) {

    // i番目のチェックボックスがチェックされているかを判定
    if (document.form1.fruits[i].checked) {
      flag = true;
      alert(document.form1.fruits[i].value + "が選択されました。");
    }
  }

  // 何も選択されていない場合の処理
  if (!flag) {
    alert("項目が選択されていません。");
  }
}


$(function () {
  $('#check-ryousyuusyo').click(function () {
    if ($("#check-ryousyuusyo").prop('checked')) {
      $('#txtbox-ryousyuusyo').prop('disabled', false);
    } else {
      $('#txtbox-ryousyuusyo').prop('disabled', true);
    }
  })
});


$(function () {
  $('#check-koushinji').click(function () {
    if ($("#check-koushinji").prop('checked')) {
      $('.koushinji-box').css('display', 'block');
      $('.tab-txtboxB').css('display', 'none');
    } else {
      $('.koushinji-box').css('display', 'none');
      $('.tab-txtboxB').css('display', 'block');
    }
  })
});


$(function () {
  $('#check-koushinjiB').click(function () {
    if ($("#check-koushinjiB").prop('checked')) {
      $('.koushinji-boxB').css('display', 'block');
      $('.tab-txtboxC').css('display', 'none');
    } else {
      $('.koushinji-boxB').css('display', 'none');
      $('.tab-txtboxC').css('display', 'block');
    }
  })
});


$(function () {
  $('#hokan-check').click(function () {
    if ($("#hokan-check").prop('checked')) {
      $('#btn-hokan-kanryou').prop('disabled', false);
    } else {
      $('#btn-hokan-kanryou').prop('disabled', true);
    }
  })
});


//radio 日割賃金

$(function () {
  $('[name="hiwari_1_r1"]:radio').change(function () {
    if ($('#hiwari_1_2').prop('checked')) {
      $('#hiwari-box').css('display', 'block');
    } else if ($('#hiwari_1_1').prop('checked')) {
      $('#hiwari-box').css('display', 'none');
    }
  });
});


//modal
$(function () {

  $(".modalOpen").click(function () {

    var navClass = $(this).attr("class"),
        href = $(this).attr("href");

    if (href === "#modal02") {
      $(href).children(".inner").css("animation", "modal 0.5s forwards");
    }
    $(href).fadeIn();
    $(this).addClass("open");
    return false;
  });

  $(".modalClose").click(function () {

    var parentsID = $(this).parents(".modal").attr("id");

    if (parentsID === "modal02") {
      $(this).parents(".modal").children(".inner").css("animation", "modalClose 0.5s forwards");
    }

    $("#" + parentsID + " button.vjs-play-control.vjs-control.vjs-button.vjs-playing").trigger("click")

    $(this).parents(".modal").fadeOut();
    $(".modalOpen").removeClass("open");
    return false;
  });


  $("#rs-btn-user").click(function () {

    var parentsID = $(this).parents(".modal").attr("id");

    if (parentsID === "modal02") {
      $(this).parents(".modal").children(".inner").css("animation", "modalClose 0.5s forwards");
    }

    $("#" + parentsID + " button.vjs-play-control.vjs-control.vjs-button.vjs-playing").trigger("click")

    $(this).parents(".modal").fadeOut();
    $(".modalOpen").removeClass("open");
    return false;
  });

});


//toggle
$(function () {
  $(".btn-open a").on("click", function () {
    $(".news-open").slideToggle();
    $(".btn-open a").toggleClass("active"); //追加部分
  })
});

$(function () {
  $("#P10-1-button-department-news-open").on("click", function () {
    event.preventDefault();
    $(".department-news-open").slideToggle();
    $("#P10-1-button-department-news-open a").toggleClass("active"); //追加部分
  });
});

$(function () {
  $("h4.ttl-close").on("click", function () {
    $(this).next().slideToggle();
    $(this).toggleClass("active"); //追加部分
  });
});

$(function () {
  $("div.ttl-close").on("click", function () {
    $(this).next().slideToggle();
    $(this).toggleClass("active"); //追加部分
  });
});


$(function () {
  $(document).on("click", "h3.ttl-open", function () {
    $(this).next().slideToggle();
    $(this).toggleClass("active"); //追加部分
  });
});


//tab
$(function () {
  $('#tabA ul.tab_area li').click(function () {
    var index = $('#tabA ul.tab_area li').index(this);
    $('#tabA .content_area').css('display', 'none');
    $('#tabA .content_area').eq(index).fadeIn();
    $('#tabA .tab_area li').removeClass('select');
    $(this).addClass('select')
  });
});


//ダイレクトリンク
$(function () {
  var hash = location.hash;
  hash = (hash.match(/^#tab\d+$/) || [])[0];
  if ($(hash).length) {
    var tabname = hash.slice(1);
  } else {
    var tabname = "tab1";
  }
  $('#tabA .content_area').css('display', 'none');
  $('#tabA .tab_area li').removeClass('select');
  var tabno = $('#tabA ul.tab_area li#' + tabname).index();
  $('#tabA .content_area').eq(tabno).fadeIn();
  $('#tabA ul.tab_area li').eq(tabno).addClass('select')
});


$(function () {
  $('#tabB ul.tab_area li').click(function () {
    var index = $('#tabB ul.tab_area li').index(this);
    $('#tabB .content_area').css('display', 'none');
    $('#tabB .content_area').eq(index).fadeIn();
    $('#tabB .tab_area li').removeClass('select');
    $(this).addClass('select')
  });
});


//ダイレクトリンク
$(function () {
  var hash = location.hash;
  hash = (hash.match(/^#tab\d+$/) || [])[0];
  if ($(hash).length) {
    var tabname = hash.slice(1);
  } else {
    var tabname = "tab1";
  }
  $('#tabB .content_area').css('display', 'none');
  $('#tabB .tab_area li').removeClass('select');
  var tabno = $('#tabB ul.tab_area li#' + tabname).index();
  $('#tabB .content_area').eq(tabno).fadeIn();
  $('#tabB ul.tab_area li').eq(tabno).addClass('select')
});


//Datepicker

$.datepicker.setDefaults({
  buttonImage: "assets/img/icon-calendar.svg",
  buttonText: "カレンダーから選択",
  buttonImageOnly: true,
  showOn: "both"
});

$(function () {

  $("#date1").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date2").datepicker({
    minDate: new Date($("#min_date2").val())
  });

  $("#date3").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date4").datepicker({
    minDate: new Date($("#min_date2").val())
  });

  $("#date5").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date6").datepicker({
    minDate: new Date($("#min_date2").val())
  });

  $("#date7").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date8").datepicker({
    minDate: new Date($("#min_date2").val())
  });

  $("#date9").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date10").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date11").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date12").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date13").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date14").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date15").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date16").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date17").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date18").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date19").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date20").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date21").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date22").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date23").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date24").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date25").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date26").datepicker({
    minDate: new Date($("#min_date1").val())
  });

  $("#date27").datepicker({
    minDate: new Date($("#min_date1").val())
  });


  // 日本語化
  $.datepicker.regional['ja'] = {
    closeText: '閉じる',
    prevText: '<前',
    nextText: '次>',
    currentText: '今日',
    monthNames: ['1月', '2月', '3月', '4月', '5月', '6月',
      '7月', '8月', '9月', '10月', '11月', '12月'
    ],
    monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月',
      '7月', '8月', '9月', '10月', '11月', '12月'
    ],
    dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
    dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
    dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
    weekHeader: '週',
    dateFormat: 'yy/mm/dd',
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: true,
    yearSuffix: '年'
  };
  $.datepicker.setDefaults($.datepicker.regional['ja']);
});


// PDF添付（input type=file）
$(function () {

  if ($('#file-pdf-name').text() == "") {
    //ファイルが未指定の場合
    $('.file-pdf-selected').hide();
  } else {
    //ファイルが指定されている場合
    $('input[type=file].file-pdf').hide();
  }

  $('input[type=file].file-pdf').change(function () {
    var file = $(this).prop('files')[0];
    $('#file-pdf-name').html(file.name);
    $('.file-pdf-selected').show();
    $(this).hide();

  });

  $('.file-pdf-reset').click(function () {
    $('input[type=file].file-pdf').show();
    $('input[type=file].file-pdf').val("");
    $('.file-pdf-selected').hide();
  })

});


// 約款インラインスクロールダウン制御（仕様変更にともない不使用）
$(function () {

  // -----------------------
  // 不使用
  return;
  // -----------------------

  $("#term_block_complete").hide();
  $('#term_block .kiyaku-box').on('scroll', function () {

    var docHeight = Math.floor($('#term_block .kiyaku-content').innerHeight()), //ドキュメントの高さ
        windowHeight = Math.floor($('#term_block .kiyaku-box').innerHeight()), //ウィンドウの高さ
        pageBottom = docHeight - windowHeight; //ドキュメントの高さ - ウィンドウの高さ

    // console.info(
    //   pageBottom,
    //   docHeight,
    //   windowHeight,
    //   $('#term_block .kiyaku-box').css("paddingBottom"),
    //   $('#term_block .kiyaku-box').scrollTop())

    if (pageBottom <= $('#term_block .kiyaku-box').scrollTop()) {
      //ウィンドウの一番下までスクロールした時に実行
      $("#btn-keiyaku-term").prop("disabled", false);
    }
  });

  $("#btn-keiyaku-term").click(function () {
    $("#btn-keiyaku-attention").hide();
    $("#btn-keiyaku-term-box").hide();
    $("#term_block_complete").show();
    $("#term_block_complete").css({
      opacity: 0.0
    });
    $("#term_block_complete").animate({
      opacity: 1.0
    }, 600);
    $('body,html').animate({
      scrollTop: $("#term_block_complete").offset().top
    }, 300);

  })

});


// 約款PDFダウンロードボタン制御
$(function () {

  $("#term_block_complete").hide();

  $("a.btn-yakkan-download").click(function () {

    if (!$(this).hasClass("kiyaku-checked")) {
      $(this).addClass("kiyaku-checked")
      $(this).html($(this).html() + "（確認済）")
    }

    if ($(".kiyaku-checked").length == 2) {
      $("#btn-keiyaku-term").prop("disabled", false);
    }


  });

  $("#btn-keiyaku-term").click(function () {
    $("#btn-keiyaku-attention").hide();
    $("#btn-keiyaku-term-box").hide();
    $("#term_block_complete").show();
    $("#term_block_complete").css({
      opacity: 0.0
    });
    $("#term_block_complete").animate({
      opacity: 1.0
    }, 600);
    $('body,html').animate({
      scrollTop: $("#term_block_complete").offset().top
    }, 300);

  })

});


//フォーム半角数字のみ
$(function () {
  $('input.number-only').on('change', function (e) {
    let value = $(e.currentTarget).val();
    value = value
        .replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        })
        .replace(/[^0-9]/g, '');
    $(e.currentTarget).val(value);
  });
});


//事故状況テキスト選択
$(function () {
  $('.focus-select').focus(function () {
    $(this).select();
  });
});


//管理会社・賃貸人の変更依頼 変更状態の切替（変更なし 変更あり 不明）
$(function () {

  $('.change-input-status').on("change", function () {
    var v = $(this).val();

    //変更ありの場合のみ入力可能にする
    var disabled = v !== "変更あり" && v !=="入力する"
    $(this).parents('section').find('input,select').not(".change-input-status").prop('disabled', disabled)
    if (disabled) {
      $(this).parents('section').find('a.modalOpen').addClass('btn-disabled')
      $(this).parents('section').find('button').addClass('btn-disabled')
    } else {
      $(this).parents('section').find('a.modalOpen').removeClass('btn-disabled')
      $(this).parents('section').find('button').removeClass('btn-disabled')
    }

  });
  $('.change-input-status:checked').trigger("change");

});

//fixItemクラス同士の高さを揃える
$(function () {

  $(window).resize(function () {
    var h = 0;

    $(".fixItem").each(function () {
      if ($(this).height() > h) {
        h = $(this).height();
      }
    });

    $(".fixItem").css("height", h + "px");
  });
  $(window).trigger("resize")

});

//申込一覧／契約一覧「状態絞込」制御
$(function () {

  $(".from-siborikomijoutai .checkbox-iraichu").change(function () {
    if ($(this).prop("checked")) {
      $(".from-siborikomijoutai .radio-input").prop("disabled", true)
      $(".from-siborikomijoutai .radio-input").eq(0).prop("checked", true)
    } else {
      $(".from-siborikomijoutai .radio-input").prop("disabled", false)
    }
  })
  $(".from-siborikomijoutai .checkbox-iraichu").trigger("change")


});


//依頼中の案件を照会する
$(function () {
  $(".from-siborikomijoutai .checkbox-soukinsaki").change(function () {
    if ($(this).prop("checked")) {
      $(".from-siborikomijoutai .soukinsaki-attention").css("display", "block")
    } else {
      $(".from-siborikomijoutai .soukinsaki-attention").css("display", "none")
    }
  })
});


$(function () {

  $('[name="shiborikomi_4_r1"]:radio').change(function () {
    if ($(".from-shiborikomijyoutai-shitagaki").prop("checked")) {
      $(".from-soushinjyoukyou .checkbox-input").prop("disabled", false)
      $(".from-soushinjyoukyou .checkbox-input").eq(0).prop("checked", false)
    } else {
      $(".from-soushinjyoukyou .checkbox-input").prop("disabled", true)
    }
  })
  $(".from-siborikomijoutai .from-shiborikomijyoutai-shitagaki").trigger("change")
});


$(function () {
  $(".from-shiborikomijyoutai-shitagaki").change(function () {
    if ($(this).prop("checked")) {
      $(".from-siborikomijoutai .soukinsaki-attention").css("display", "block")
    } else {
      $(".from-siborikomijoutai .soukinsaki-attention").css("display", "none")
    }
  })
});


//入会手続方法
$(function () {
  $('#error-close01').on('click', () => {
    $('#error01').hide();
  });
});

$(function () {
  $('#error-close02').on('click', () => {
    $('#error02').hide();
  });
});


$(function () {
  $('[name="tr_1_r1"]:radio').change(function () {
    if ($('[id=tr_1_1]').prop('checked')) {
      $('.tbox').css("display", "none")
      $('#t-sns').css("display", "block")
      $('#keiyakubi').css("display", "none")
    } else if ($('[id=tr_1_2]').prop('checked')) {
      $('.tbox').css("display", "none")
      $('#t-email').css("display", "block")
      $('#keiyakubi').css("display", "none")
    } else if ($('[id=tr_1_3]').prop('checked')) {
      $('.tbox').css("display", "none")
      $('#keiyakubi').css("display", "block")
    }
  });
});


//申込申請方法
$(function () {
  $('[name="mr_1_r1"]:radio').change(function () {
    if ($('[id=mr_1_1]').prop('checked')) {
      $('.tbox').css("display", "none")
      $('.foot-btnbox').css("display", "none")
      $('.shitagakikakunin').css("display", "block")
      $('.m-hide').css("display", "block")
      $('.m-hideB').css("display", "block")
    } else if ($('[id=mr_1_2]').prop('checked')) {
      $('.tbox').css("display", "none")
      $('#m-sns').css("display", "block")
      $('.foot-btnbox').css("display", "none")
      $('.soushin').css("display", "block")
      $('.m-hide').css("display", "none")
      $('.m-hideB').css("display", "block")
    } else if ($('[id=mr_1_3]').prop('checked')) {
      $('.tbox').css("display", "none")
      $('#m-email').css("display", "block")
      $('.foot-btnbox').css("display", "none")
      $('.soushin').css("display", "block")
      $('.m-hide').css("display", "none")
      $('.m-hideB').css("display", "block")
    } else if ($('[id=mr_1_4]').prop('checked')) {
      $('.tbox').css("display", "none")
      $('#m-download').css("display", "block")
      $('.foot-btnbox').css("display", "none")
      $('.shitagakikakunin').css("display", "block")
      $('.m-hideB').css("display", "none")
      $('.m-hide').css("display", "none")
    }
  });
});


//領収書
$(function () {
  $('[name="rs_1_r1"]:radio').change(function () {
    if ($('[id=rs_1_1]').prop('checked')) {
      $('#rs-btn-mail').css("display", "none")
      $('#rs-btn-gamen').css("display", "inline")
    } else if ($('[id=rs_1_2]').prop('checked')) {
      $('#rs-btn-gamen').css("display", "none")
      $('#rs-btn-mail').css("display", "inline")
    }
  });

  $('[name="rs_1_r1"]:radio').change(function () {
    if ($('[id=rs_1_2]').is(':checked')) {
      $('#rs-mail-txt').prop('disabled', false);
    } else {
      $('#rs-mail-txt').prop('disabled', true);
    }
  });

});



//支払口座情報
$(function () {
  $('[name="kouza_1_r1"]:radio').change(function () {
    if ($('[id=kouza_1_1]').is(':checked')) {
      $('#shiten-code').prop('disabled', true);
      $('#shiten-name').prop('disabled', true);
    } else {
      $('#shiten-code').prop('disabled', false);
      $('#shiten-name').prop('disabled', false);
    }
  });
});




//JID連携
$(function () {
  $(".jid-renkei").change(function () {
    if ($(this).prop("checked")) {
      $(".hoshoubangou-area").css("display", "block")
    } else {
      $(".hoshoubangou-area").css("display", "none")
    }
  })
});


//サービス内容

$(function () {
  $('[name="service_1_r1"]:radio').change(function () {
    if ($('[id=service_1_1]').prop('checked')) {
      $('.nyukai-tetsuduki').css("display", "block")
      $('.bukken-jyouhou').css("border-bottom", "1px solid #0077b7")
    } else if ($('[id=service_1_2]').prop('checked')) {
      $('.nyukai-tetsuduki').css("display", "none")
      $('.bukken-jyouhou').css("border", "none")
    } else if ($('[id=service_1_3]').prop('checked')) {
      $('.nyukai-tetsuduki').css("display", "none")
      $('.bukken-jyouhou').css("border", "none")
    }
  });
});


//請求・証明確認 Navi
/*$(function () {
  $('#update_user_form_read_invoice').click(function () {
    if ($("#update_user_form_read_invoice").prop('checked')) {
      $('.nav08').css('display', 'block');
    } else {
      $('.nav08').css('display', 'none');
    }
  })
});*/

//ふきだし
$(function () {
  $(".balloon").after().hide();
  $(".btn-attention").click(function () {
    $(".balloon").toggleClass("open").slideToggle("fast", function () {
      $("body,html").scrollTop();
    });
  });
});


$(function () {
  $(".balloon-b").after().hide();
  $(".btn-attention-b").click(function () {
    $(".balloon-b").toggleClass("open").slideToggle("fast", function () {
      $("body,html").scrollTop();
    });
  });
});

$(function () {
  $(".balloon-c").after().hide();
  $(".btn-attention-c").click(function () {
    $(".balloon-c").toggleClass("open").slideToggle("fast", function () {
      $("body,html").scrollTop();
    });
  });
});

//ファイル選択 削除
jQuery
$(function () {
  // アップロードするファイルを選択
  $('input[type=file]').change(function () {
    $('#clear').show();
  });

  // ユーザエージェント
  var ua = navigator.userAgent;

  // ファイル参照をクリア
  $('#clear').click(function () {
    $('input[type=file]').val('');
    // IE バージョン判定 10以下
    if (ua.match(/MSIE\s(7|8|9|10)\./i)) {
      $('#userfile_item').html('<input type="file" name="userfile">');
    }
    $(this).hide();
  });
});

//保証委託契約書のアップロード
$(function () {
  $('[name="upload_1_r1"]:radio').change(function () {
    if ($('[id=upload_1_1]').prop('checked')) {
      $('#tempfile-box').css("display", "block");
      $('.shitagakikakunin').css("display", "block");
      $('.next-qr').css("display", "none");
      $('.kakunin-box').css("display", "block");
      $('.next-box').css("display", "none");
      $('#upload-smartphone').css("display", "none");
    } else if ($('[id=upload_1_2]').prop('checked')) {
      $('#tempfile-box').css("display", "none");
      $('.shitagakikakunin').css("display", "none");
      $('.next-qr').css("display", "block");
      $('.kakunin-box').css("display", "none");
      $('.next-box').css("display", "block");
      $('#upload-smartphone').css("display", "block");
    }
  });
});

//ロック
$(document).ready(function(){
  //デフォルトで表示する要素を指定
  $('#lock-area-off').css("display", "block");
  $('#lock-area-on').css("display", "none");

  //buttonがクリックされたら処理を実行
  $('#btn-lock-on').click(function () {
    $('#lock-area-off').css("display", "none");
    $('#lock-area-on').css("display", "block");
  });

  $('#btn-yes').click(function () {
    $('#lock-area-off').css("display", "block");
    $('#lock-area-on').css("display", "none");
  });
});


//保証金精算依頼を開始
$(document).ready(function(){
  //デフォルトで表示する要素を指定
  $('.btn-iraikaishi').css("display", "inline");
  $('.btn-irai').css("display", "none");

  //buttonがクリックされたら処理を実行
  $('.btn-iraikaishi').click(function () {
    $('.btn-iraikaishi').css("display", "none");
    $('.btn-irai').css("display", "inline");
  });

});

// 05-01画面
$(document).ready(function () {
  // KHまたはHMで始まるコードの表示切り替え
  $("#P05-01-checkbox-kh-trigger").on("change", function () {
    $("#P05-01-checkbox-kh-content").toggle($(this).is(":checked"));
    $('#P05-01-kh-code-left').val('');
    $('#P05-01-kh-code-right').val('');
  });

  // 課税事業者登録番号の表示切り替え
  $("input[id^=P05-01-business-operator]").on("change", function () {
    $("#P05-01-business-operator-content").toggle($('#P05-01-business-operator-1').is(":checked"));

    // 免税事業者が選択されたときの追加処理
    if ($('#P05-01-business-operator-2').is(':checked')) {
      $('#P05-01-register-number').val(''); // P05-01-register-numberの値をリセット
    }
  })

  // 宅地建物取引業免許の表示切り替え
  $("input[id^=P05-01-takuti-torihiki]").on("change", function () {
    $("#P05-01-takuti-torihiki-content").toggle($('#P05-01-takuti-torihiki-1').is(":checked"));
    // なしが選択されたときの追加処理
    if ($('#P05-01-takuti-torihiki-2').is(':checked')) {
      $('#P05-01-select-prefecture').val('');
      $('#P05-01-license-details').val('');
      $('#P05-01-license-number').val('');
    }
  })

  // 確認画面遷移ボタンの制御
  function checkSubmitButtonDisabled() {
    const paymentMethodChecked = $('#P05-01-payment-method-debit').is(":checked") || ($('#P05-01-payment-method-transfer').is(":checked") && $("input#P05-01-check-furikomi").is(":checked"))
    const disabled =
        !$("input#P05-01-check-yakkan").is(":checked") ||
        !$("input#P05-01-check-kiyaku").is(":checked") ||
        !paymentMethodChecked
    $('#P05-01-submit').prop("disabled", disabled);
  }

  // 請求支払方法詳細の表示切り替え
  $("input[id^=P05-01-payment-method]").on("change", function () {
    $("#P05-01-payment-method-debit-content").toggle($('#P05-01-payment-method-debit').is(":checked"));
    $("#P05-01-payment-method-transfer-content").toggle($('#P05-01-payment-method-transfer').is(":checked"));
    checkSubmitButtonDisabled()
  })

  // 規約同意チェックボックスの利用可否制御
  $("a[data-terms-confirm^='#P05-01-check']").on("click", function (e) {
    const $target = $($(this).data('terms-confirm'))
    if (!$target.prop("disabled")) return
    $target.prop("disabled", false);
    $(this).addClass('is-checked')
  })

  // 規約同意チェックボックスの状態制御
  $("input[id^=P05-01-check]").on('change', checkSubmitButtonDisabled)
});

//23-1画面
$(document).ready(function () {
  // 課税事業者登録番号チェックボックスの状態制御
  // デフォルトで変更なしが選択されているためboxをグレーアウト
  if ($("#P23-1-kazei-change-status-no").prop("checked")) $(".business-number").addClass("is-disabled");

  $("input[id^=P23-1-kazei-change-status]").on("change", function () {
    $('#P23-1-register-number').prop('disabled', $("input#P23-1-kazei-change-status-no").is(":checked") );
    if ($(this).val() === "変更なし") {
      $("#P23-1-register-number").val("")
      $(".business-number").addClass("is-disabled")
    } else {
      $(".business-number").removeClass("is-disabled")
    }
  });

  // 代理店名チェックボックスの状態制御
  $("input[id^=P23-1-dairi-change-status]").on("change", function () {
    $('#P23-1-company-name').prop('disabled', $("input#P23-1-dairi-change-status-no").is(":checked") );
    $('#P23-1-company-name-kana').prop('disabled', $("input#P23-1-dairi-change-status-no").is(":checked") );
    if ($(this).val() === "変更なし") {
      $("#P23-1-company-name").val("")
      $("#P23-1-company-name-kana").val("")}
  });

  // 代理店所在地チェックボックスの状態制御
  $("input[id^=P23-1-dairi-address-change-status]").on("change", function () {
    $('#P23-1-select-postcode').prop('disabled', $("input#P23-1-dairi-address-change-status-no").is(":checked") );
    $('#P23-1-select-prefecture-driver-license').prop('disabled', $("input#P23-1-dairi-address-change-status-no").is(":checked") );
    $('#P23-1-select-town').prop('disabled', $("input#P23-1-dairi-address-change-status-no").is(":checked") );
    $('#P23-1-search-address').prop('disabled', $("input#P23-1-dairi-address-change-status-no").is(":checked") );
    $('#P23-1-select-street').prop('disabled', $("input#P23-1-dairi-address-change-status-no").is(":checked") );
    $('#P23-1-select-building').prop('disabled', $("input#P23-1-dairi-address-change-status-no").is(":checked") );
    if ($(this).val() === "変更なし") {
      $("#P23-1-select-postcode").val("")
      $("#P23-1-select-prefecture-driver-license").val("")
      $("#P23-1-select-town").val("")
      $("#P23-1-search-address").val("")
      $("#P23-1-select-street").val("")
      $("#P23-1-select-building").val("")
    }
  });

  // 代理店連絡先チェックボックスの状態制御
  $("input[id^=P23-1-dairi-contact-change-status]").on("change", function () {
    $('#P23-1-contact-number-1').prop('disabled', $("input#P23-1-dairi-contact-change-status-no").is(":checked") );
    $('#P23-1-contact-number-2').prop('disabled', $("input#P23-1-dairi-contact-change-status-no").is(":checked") );
    $('#P23-1-contact-number-3').prop('disabled', $("input#P23-1-dairi-contact-change-status-no").is(":checked") );
    $('#P23-1-fax-number-1').prop('disabled', $("input#P23-1-dairi-contact-change-status-no").is(":checked") );
    $('#P23-1-fax-number-2').prop('disabled', $("input#P23-1-dairi-contact-change-status-no").is(":checked") );
    $('#P23-1-fax-number-3').prop('disabled', $("input#P23-1-dairi-contact-change-status-no").is(":checked") );
    if ($(this).val() === "変更なし") {
      $("#P23-1-contact-number-1").val("")
      $("#P23-1-contact-number-2").val("")
      $("#P23-1-contact-number-3").val("")
      $("#P23-1-fax-number-1").val("")
      $("#P23-1-fax-number-2").val("")
      $("#P23-1-fax-number-3").val("")
    }
  });

  // 宅地建物取引業チェックボックスの状態制御
    // デフォルトで変更なしが選択されているためboxをグレーアウト
  if ($("#P23-1-takuchi-change-status-no").prop("checked")) {
    $(".box-takuken-number").addClass("is-disabled");
  }

  $("input[id^=P23-1-takuchi-change-status]").on("change", function () {
    $('#P23-1-select-prefecture-takuchi').prop('disabled', $("input#P23-1-takuchi-change-status-no").is(":checked") );
    $('#P23-1-driver-license-renewal').prop('disabled', $("input#P23-1-takuchi-change-status-no").is(":checked") );
    $('#P23-1-driver-license-number').prop('disabled', $("input#P23-1-takuchi-change-status-no").is(":checked") );
    if ($(this).val() === "変更なし") {
      $("#P23-1-select-prefecture-takuchi").val("")
      $("#P23-1-driver-license-renewal").val("")
      $("#P23-1-driver-license-number").val("")
      $(".box-takuken-number").addClass("is-disabled")
    } else {
      $(".box-takuken-number").removeClass("is-disabled")
    }
  });

  // 代表者チェックボックスの状態制御
  $("input[id^=P23-1-representive-change-status]").on("change", function () {
    $('#P23-1-representive-name').prop('disabled', $("input#P23-1-representive-change-status-no").is(":checked") );
    $('#P23-1-representive-name-kana').prop('disabled', $("input#P23-1-representive-change-status-no").is(":checked") );
    if ($(this).val() === "変更なし") {
      $("#P23-1-representive-name").val("")
      $("#P23-1-representive-name-kana").val("")
    }
  });
});

//23-2画面
$(document).ready(function () {
  $("#toast-message").hide()

  $("#btn-doui").on("click", function() {
    $("#toast-message").show()
  });
});

//13-1-1画面
$(document).ready(function() {
  // ファイル選択ボタン、ドラッグ＆ドロップ領域のクリックでファイル選択ダイアログ
  $(document).on('click', '.drag-and-drop-area', function() {
    $('.drag-and-drop-file-select-input').click();
  });

  // ファイル選択後
  $(document).on('change', '.drag-and-drop-file-select-input', function(event) {
    const files = event.target.files;
    if (files.length > 0) {
      $('.drag-and-drop-file-name').text(files[0].name); // ファイル名を表示
    }
  });

  $(document).on('dragover', '.drag-and-drop-area', function(event) {
    $(this).addClass('active');
    event.preventDefault();
    event.originalEvent.dataTransfer.dropEffect = 'copy';
  });

  $(document).on('dragleave', '.drag-and-drop-area', function(event) {
    $(this).removeClass('active');
  });

  $(document).on('drop', '.drag-and-drop-area', function(event) {
    event.preventDefault();
    $(this).removeClass('active');
    const files = event.originalEvent.dataTransfer.files;
    if (files.length !== 1) return;

    $('.drag-and-drop-file-name').text(files[0].name);
    document.getElementById('js-request-upload-file__file').files = files
  });
});

//12-1画面
$(document).ready(function() {
  // 入金約束日の制御
  $('#P12-1-payment-due-date').hide();
  $('label[for="P12-1-payment-due-date"]').hide();

  $('input[name="shiborikomi"]').change(function() {
      if ($('#P12-1-status-report').is(':checked') || $('#P12-1-checklist-item').is(':checked')) {
          $('#P12-1-payment-due-date').show();
          $('label[for="P12-1-payment-due-date"]').show();
      } else {
          $('#P12-1-payment-due-date').hide();
          $('label[for="P12-1-payment-due-date"]').hide();
      }

  $("#P12-1-checkbox-iraichu").change(function(){
    if ($('#P12-1-checkbox-iraichu').is(':checked')) {
      $('#P12-1-payment-due-date').hide();
      $('label[for="P12-1-payment-due-date"]').hide();
      $("#P12-1-payment-due-date").prop('checked', false)
    }
  })
  });

    // 契約予定日の制御
    $('#P12-1-scheduled-contract-date').hide();
    $('label[for="P12-1-scheduled-contract-date"]').hide();
    $('.P12-1-unfilled-payment-day').hide();
    $(".balloon-box-unfilled-payment-day").hide()

    $("input[name=shiborikomi]").change(function() {
        if ($('#P12-1-new-uncontracted').is(':checked')) {
            $('#P12-1-scheduled-contract-date').show();
            $('label[for="P12-1-scheduled-contract-date"]').show();
            $('.P12-1-unfilled-payment-day').show();
            $(".balloon-box-unfilled-payment-day").show()
        } else {
            $('#P12-1-scheduled-contract-date').hide();
            $('label[for="P12-1-scheduled-contract-date"]').hide();
            $('.P12-1-unfilled-payment-day').hide();
            $(".balloon-box-unfilled-payment-day").hide()
        }
    });

    $("input[name=checkbox").change(function() {
      if($('#P12-1-new-uncontracted').is(':checked') && $("#P12-1-check-unfilled-payment-day")) {
        $("#P12-1-scheduled-contract-date").prop('checked', false)
      }
    })

    // 「契約予定日が未入力のみ」の制御
    $("#P12-1-check-unfilled-payment-day").on("change",function() {
      if($("#P12-1-check-unfilled-payment-day").is(":checked")) {
        $("#P12-1-scheduled-contract-date").prop("disabled", $("#P12-1-check-unfilled-payment-day").is(":checked"))
      }
      if (!$("#P12-1-check-unfilled-payment-day").is(":checked")) {
        $("#P12-1-scheduled-contract-date").prop("disabled", false);
      }
    });

    // モーダルの表示制御
    $("[data-modal]").on("click", function() {
      $("#modal07").show();
    });

    // モーダルを閉じた際の制御
    $("#P12-1-modal-close").on("click", function() {
      $("#date1").val("")
      $("#date2").val("")
      $('#P12-1-btn-setting-1').prop('disabled', true);
      $('#P12-1-btn-setting-2').prop('disabled', true);
    })


    //　モーダルの設定ボタンの制御
    $('#date1').on('change', function() {
      if ($(this).val().trim() !== '') {
        $('#P12-1-btn-setting-1').prop('disabled', false);
      } else {
        $('#P12-1-btn-setting-1').prop('disabled', true);
      }
    });

    $('#date2').on('change', function() {
      if ($(this).val().trim() !== '') {
        $('#P12-1-btn-setting-2').prop('disabled', false);
      } else {
        $('#P12-1-btn-setting-2').prop('disabled', true);
      }
    });
  })
